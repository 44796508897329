import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Lenis from '@studio-freight/lenis';

document.addEventListener('DOMContentLoaded', onLoad);

function onLoad(){
    gsap.registerPlugin(ScrollTrigger);
	document.querySelector('.loader').classList.remove('loader_show')
    initScroll();
    initHeader();
    initCursor();
}

function initScroll(){
    const lenis = new Lenis({
        lerp: 0.07
    });
    
    lenis.on('scroll', ScrollTrigger.update);    
        gsap.ticker.add((time)=>{
        lenis.raf(time * 1000)
    })  
    gsap.utils.toArray('picture:not(.gallery__item)').forEach(container => {
        const img = container.querySelector('img');
      
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: container,
            scrub: true,
            pin: false,
          }
        });
      
        tl.fromTo(img, {
          yPercent: -20,
          ease: 'none'
        }, {
          yPercent: 20,
          ease: 'none'
        });
    });

	const section = document.querySelector(".main-page");
	let currentPixel = window.pageYOffset

	//looper keeps running and keeps track of where the new pixel is
	const looper = function () {
	const newPixel = window.pageYOffset;
	const diff = newPixel - currentPixel
	const speed = diff * 0.2;
	
	section.style.transform = "skewY(" + speed + "deg)"
	
	
	currentPixel = newPixel;
	
	requestAnimationFrame(looper)
	}

	looper();
}

function initHeader(){
    const header = document.querySelector('.header');
    const toggleButton = header.querySelector('.header__burger');
	let tl = null;
	const html = document.querySelector('html');
    toggleButton.addEventListener('click', e => {
		const stagger = 0.15;
		if(tl){
			tl.kill();
		}
		tl = gsap.timeline();
		if(!header.classList.contains('header_open')){
			header.classList.add('header_open');
			html.classList.add('scroll-disabled');
			tl.set(`.header__item-title, .header__separate, .header__item-description, .header__info-item`, 
				{ x: -20, opacity: 0 });

			setTimeout(() => {
				tl.to(".header__item-title, .header__separate", 
					{ x: 0, stagger: stagger, opacity: 1 })
				.to(".header__item-description", 
					{ x: 0, stagger: stagger, opacity: 1, }, "<0.5")
				.to(".header__info-item ", 
					{ x: 0, stagger: stagger, opacity: 1, }, ">-0.5");
			}, 700);
			
		}else{
			tl.to(".header__item-title, .header__separate, .header__item-description, .header__info-item", 
				{ x: -20, stagger: 0.01, opacity: 0 })
			.call(() => {
				header.classList.remove('header_open');
				html.classList.remove('scroll-disabled');
			});
		}
	})

	gsap.from(".header__logo, .header__wrapper", {
		x: -20,
		opacity: 0,
		stagger: 0.2,
		duration: 2,
		delay: 2
	})
}

function initCursor(){
    let cx, cy, mouseX, mouseY, posX, posY;
    const body = document.querySelector('body');

	cx = window.innerWidth / 2;
	cy = window.innerHeight / 2;

	body.addEventListener('mousemove', e => {
		mouseCoords(e);
		cursor.classList.remove('hidden');
		follower.classList.remove('hidden');

	})

	const cursor   = document.querySelector('.cursor');
	const follower = document.querySelector('.cursor-aura');

	mouseX = 0, mouseY = 0, posX = 0, posY = 0

	function mouseCoords(e) {
		mouseX = e.clientX;
		mouseY = e.clientY;
	}

	gsap.to({}, .01, {

		repeat: -1,

		onRepeat: () => {

			posX += (mouseX - posX) / 5
			posY += (mouseY - posY) / 5

			gsap.set(cursor, {
				css: {
					left: mouseX,
					top: mouseY
				}
			})

			gsap.set(follower, {
				css: {
					left: posX - 24,
					top: posY - 24
				}
			})

		}

	})

    document.querySelectorAll('a, .link').forEach(x => {
        x.addEventListener('mouseover', () => {
			cursor.classList.add('active')
			follower.classList.add('active')
		})

		x.addEventListener('mouseout', () => {
			cursor.classList.remove('active')
			follower.classList.remove('active')
		})
    })

	body.addEventListener('mouseout', () => {
		cursor.classList.add('hidden')
		follower.classList.add('hidden')
	})
}


