import './global/global';
import { SplitText } from './plugins/splittext';
import { fslightbox } from './plugins/fslightbox';
import { CountUp } from './plugins/countUp';
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', initMainPage);

function initMainPage(){  
	initOffer();
    initAbout();
    initEvents();
    initGallery();
}

function initOffer(){
    const tl = gsap.timeline({        
        defaults:{
            duration: 1,
        }
    });
    gsap.utils.toArray('.offer__title span').forEach((x, i) => {
        const text = new SplitText(x, {words: 1, chars: 1, spacing: 10});
        tl.from(text.chars, {
            y: 20,
            stagger: 0.1,
            opacity: 0,
            ease: 'sine.out',
            delay: .3 * i
        }, `<${(i - 1) * -0.1}`)
    })
    tl.from('.offer__title img', {
        width: 0,
        ease: "circ.out",
        duration: 2,
        opacity: 0
    }, "<-0.3")
    .from('.offer__image', {
        width: 0,
        ease: "circ.out",
        duration: 2,
        opacity: 0
    }, "<0.5")
    .from('.offer__text', {
        y: 20,
        opacity: 0
    }, "<0.5");
}

function initAbout(){
    
    initAboutOfferRow();
    initAboutFirstRow();
    initAboutSecondRow();
    initAboutMobileRow();
}

function initAboutOfferRow(){
    let counter = new CountUp(document.querySelector('.about__item-amount'), 13000, {
        separator: '.', 
        useEasing: true,
        duration: 4
    });
    
    const tlOfferRow = gsap.timeline({        
        defaults:{
            duration: 1
        },
    });
    tlOfferRow.from('.about__row_offer .about__item-image img', {
        width: 0,
        ease: "circ.out",
        duration: 2,
        stagger: 0.3,
        opacity: 0
    })
    .from('.about__row_offer .about__item-title', {
        y: 20,
        stagger: 0.3,
        opacity: 0,
    }, "<0.5")
    .from('.about__item-text, .about__item-amount', {
        y: 20,
        stagger: 0.3,
        opacity: 0
    }, "<0.5")


    ScrollTrigger.create({
        trigger: '.main-page__about',
        start: 'top 80%',
        animation: tlOfferRow,
        onEnter: () => counter.start()
    });
}

function initAboutFirstRow(){
    const tlFirstRow = gsap.timeline({        
        defaults:{
            duration: 1
        },
    });
    tlFirstRow.from('.about__row_first .about__item-image img', {
        width: 0,
        ease: "circ.out",
        duration: 2,
        stagger: 0.3,
        opacity: 0
    })
    .from('.about__row_first .about__item-title', {
        y: 20,
        stagger: 0.3,
        opacity: 0,
    }, "<0.5")


    ScrollTrigger.create({
        trigger: '.about__row_first',
        start: 'top 80%',
        animation: tlFirstRow
    })
}

function initAboutSecondRow(){
    const tlSecontRow = gsap.timeline({        
        defaults:{
            duration: 1
        },
    });
    tlSecontRow.from('.about__row_second .about__item-image img', {
        width: 0,
        ease: "circ.out",
        duration: 2,
        stagger: 0.3,
        opacity: 0
    })
    .from('.about__row_second .about__item-title', {
        y: 20,
        stagger: 0.3,
        opacity: 0,
    }, "<0.5")


    ScrollTrigger.create({
        trigger: '.about__row_second',
        start: 'top 80%',
        animation: tlSecontRow
    })
}

function initAboutMobileRow(){
    const tlMobileRow = gsap.timeline({        
        defaults:{
            duration: 1
        },
    });
    tlMobileRow.from('.about__row_mobile .about__item-image img', {
        width: 0,
        ease: "circ.out",
        duration: 2,
        stagger: 0.3,
        opacity: 0
    })
    .from('.about__row_mobile .about__item-title', {
        y: 20,
        stagger: 0.3,
        opacity: 0,
    }, "<0.5")


    ScrollTrigger.create({
        trigger: '.about__row_mobile',
        start: 'top 80%',
        animation: tlMobileRow
    })
}

function initEvents(){
    gsap.from('.events__subtitle, .events__title', {
        x: -20,
        stagger: 0.3,
        opacity: 0,
        scrollTrigger:{
            trigger: '.events',
            start: 'top 80%',
        }
    });
    gsap.from('.events__more', {
        opacity: 0,
        scrollTrigger:{
            trigger: '.events__more',
            start: 'top 90%',
        }
    });

    gsap.utils.toArray(".events__item").forEach((x, i) => {
        const tl = gsap.timeline({        
            defaults:{
                duration: 1
            },
        });
        tl.from(x.querySelectorAll('.events__item-title, .events__item-date'), {
            x: -20,
            stagger: 0.3,
            opacity: 0,
        })
        .from(x.querySelector('.events__item-image img'), {
            width: 0,
            ease: "circ.out",
            duration: 2,
            stagger: 0.3,
            opacity: 0
        }, "<0.5")
        .from(x.querySelectorAll('.events__item-button'), {
            x: -20,
            stagger: 0.3,
            opacity: 0,
        }, "<0.5")
    
    
        ScrollTrigger.create({
            trigger: x,
            start: 'top 80%',
            animation: tl
        })
    })
}

function initGallery(){
    const gallery = document.querySelector('.gallery');
    const progressSpan = document.querySelector('.gallery__info-progress span');

    const tl = gsap.timeline({
        defaults:{
            duration: 1
        }
    })
    tl.from('.gallery__info-from, .gallery__info-progress, .gallery__info-to, .gallery__info-title', {
        x: -20,
        opacity: 0,
        ease: "circ.out",
        stagger: 0.3
    })
    ScrollTrigger.create({
        trigger: gallery,
        start: 'top 80%',
        animation: tl
    })

    new Swiper('.gallery__list', {
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: 40,
        loop: false,
        on:{
            progress: (swiper, progress) => {
                let p = (progress * 100 - 30).toFixed();
                if(p < 0){
                    p = 0;
                }
                progressSpan.style.left = `${(p)}%`
            }
        }
      });

    gsap.from('.gallery__list', {
        xPercent: -100,
        duration: 2,
        opacity: 0,
        ease: "circ.out",
        scrollTrigger: {
            trigger: gallery,
            start: 'top bottom',
        }
    })
}